<template>
  <div class="d-flex justify-content-between bg-light-primary rounded p-2">
    <div>
      <span class="text-body mr" style="font-size:30px">฿</span>
      <h2 class="d-inline " style="font-size:40px;">
        {{ credit ? credit.result.credit : '...' }}
      </h2>
      <span class="text-body" style="font-size:25px">บาท</span>
    </div>
    <div>
      <b-badge
        pill
        variant="light-primary"
        style="cursor:pointer"
        @click="getCredit"
      >
        รีเฟรช
      </b-badge>
    </div>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'BalanceBox',
  components: {
    BBadge
  },
  data() {
    return {
      credit: null
    }
  },
  methods: {
    async getCredit() {
      console.log('GETCE')
      const resp = await this.$http.get('/member/credit')
      this.credit = resp.data
    }
  },
  async beforeMount() {
    await this.getCredit()
    // console.log(this.credit)
  }
}
</script>
